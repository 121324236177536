// import { getCommonParamsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'name',
      label: 'user.resource-group-name'
    },
    {
      prop: 'description',
      label: 'user.resource-group-desc'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 180
    },
    {
      label: 'common.app-operation',
      width: 280,
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
